import React, { Component } from 'react';
import Nav from "../../components/NavigationBlog/NavigationBlog"
import ProgressBar from "../../elements/ProgressBar/ProgressBar"
import TeamBlock from '../../elements/TeamBlock/TeamBlock'
import {BodyWrapper,EmptyPageHeading,TeamWrap,TeamHeading,TeamGrid} from "../../css/style"
class team extends Component {
    constructor (props){
        super(props);
        this.state = {
            scrollPosition: 0,
        }
    }
    listenToScrollEvent = () => {
        document.addEventListener("scroll", () => {
          requestAnimationFrame(() => {
            this.calculateScrollDistance();
          });
        });
      }
    
      calculateScrollDistance = () => {
        const scrollTop = window.pageYOffset; // how much the user has scrolled by
        const winHeight = window.innerHeight;
        const docHeight = this.getDocHeight();
    
        const totalDocScrollLength = docHeight - winHeight;
        const scrollPostion = Math.floor(scrollTop / totalDocScrollLength * 100)
        this.setState({
          scrollPostion,
        });
      }
    
      getDocHeight = () => {
        return Math.max(
          document.body.scrollHeight, document.documentElement.scrollHeight,
          document.body.offsetHeight, document.documentElement.offsetHeight,
          document.body.clientHeight, document.documentElement.clientHeight
        );
      }
      componentDidMount() {
        this.listenToScrollEvent();
      }
    render() {
        return (
            <BodyWrapper>
            <Nav></Nav>
            <ProgressBar scroll={this.state.scrollPostion + '%'} active={true} />
            <TeamWrap style={{"margin-top":"10vh"}}>
                <TeamHeading>Team</TeamHeading>                
                <TeamGrid>
                {this.props.data.allWpTeam.nodes.map(({ node },index) => (
                    // console.log(this.props.data.allWpTeam.nodes[index].categories.nodes[0].name)
                    this.props.data.allWpTeam.nodes[index].categories.nodes[0].name=="Team"?
                   <TeamBlock 
                   designation={this.props.data.allWpTeam.nodes[index].team.designation}
                   name={this.props.data.allWpTeam.nodes[index].team.memberName} 
                   description={this.props.data.allWpTeam.nodes[index].team.description}
                   image={this.props.data.allWpTeam.nodes[index].team.image.mediaItemUrl} 
                   hoverState={false} />
                   :""        
                ))}  
                </TeamGrid>
            </TeamWrap>
            <TeamWrap>
                <TeamHeading>Editorial Board</TeamHeading>                
                <TeamGrid>
                {this.props.data.allWpTeam.nodes.map(({ node },index) => (
                    // console.log(this.props.data.allWpTeam.nodes[index].categories.nodes[0].name)
                    this.props.data.allWpTeam.nodes[index].categories.nodes[0].name=="Editorial Board"?
                    <TeamBlock 
                    designation={this.props.data.allWpTeam.nodes[index].team.designation}
                    name={this.props.data.allWpTeam.nodes[index].team.memberName} 
                    description={this.props.data.allWpTeam.nodes[index].team.description}
                    image={this.props.data.allWpTeam.nodes[index].team.image.mediaItemUrl} 
                    hoverState={false}/>:""        
                ))}  
                </TeamGrid>
            </TeamWrap>
            <TeamWrap>
                <TeamHeading>Advisory Board</TeamHeading>                
                <TeamGrid>
                {this.props.data.allWpTeam.nodes.map(({ node },index) => (
                    this.props.data.allWpTeam.nodes[index].categories.nodes[0].name=="Advisory Board"?
                    <TeamBlock 
                    designation={this.props.data.allWpTeam.nodes[index].team.designation}
                    name={this.props.data.allWpTeam.nodes[index].team.memberName} 
                    description={this.props.data.allWpTeam.nodes[index].team.description}
                    image={this.props.data.allWpTeam.nodes[index].team.image.mediaItemUrl} 
                    hoverState={false}/>:""        
                ))}  
                </TeamGrid>
            </TeamWrap>
            </BodyWrapper>
        );
    }
}
export default team;