// import React from "react"
// import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Loader from "../components/loader";
import Team from "../views/Team/team"
import React, { Component } from 'react';
import Logo from "../images/logo.png"
class AboutPage extends Component {
  constructor(props) {
    super(props);
  }
  
  
  render() {    
    const { data } = this.props;
    return (
      <Layout>
        <SEO title="Our Team" url="https://thesikhmag.com/team" />
        <Team data={data} ></Team>
      </Layout>      
    );
    
  }
}
export default AboutPage
export const query = graphql`
  query{
    allWpTeam(sort: {fields: team___memberName}) {
      nodes {
        team {
          description
          designation
          memberName
          image {
            mediaItemUrl
          }
        }
        categories {
          nodes {
            name
          }
        }
      }
    }
  }
`
