import React, { Component } from 'react';
import Image from "../../images/Amri-Website.jpeg"
import "./css/teamBlock.css"
class TeamBlock extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            overlay: false,
        }
        this.toggleOverlay = this.toggleOverlay.bind(this);
        this.toggleOverlayHide = this.toggleOverlayHide.bind(this)        
        this.toggleOverlayMobile = this.toggleOverlayMobile.bind(this)
        
    }
    toggleOverlayMobile()
    {
        this.setState({
            overlay:!this.state.overlay,
        });
    }
    toggleOverlay()
    {
        this.setState({
            overlay:true,
        });
    }
    toggleOverlayHide()
    {
        this.setState({
            overlay:false,
        });
    }
    componentDidMount(){
        this.setState({
            overlay:this.props.hoverState,
        })
    }
    render() {
        return (
            <div className="team-block" onMouseEnter={this.toggleOverlay}  onMouseLeave={this.toggleOverlayHide} onClick={this.toggleOverlayMobile}>
                    <div className="team-image-wrap">
                        <img src={this.props.image} loading="lazy" alt="" className={this.state.overlay? "team-image-normal blur":"team-image-normal"}/>
                    </div>
                    
                
                
                {/* <img src={this.props.image} loading="lazy" alt="" className={this.state.overlay? "team-image-blur active":"team-image-blur"}/> */}
                <div className={this.state.overlay? "team-overlay-opacity active":"team-overlay-opacity"}>
                    <div className="team-member-details">
                    <div className="team-member-designation-block">
                        <h1 className="team-member-name">{this.props.name}</h1>
                        <h2 className="team-designation">{this.props.designation}</h2>
                    </div>
                    <p className={this.state.overlay? "team-member-description active":"team-member-description"}>{this.props.description}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default TeamBlock;